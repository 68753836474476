import { defineStore } from 'pinia'
import { useStore } from "@/stores/store"

export const useUser = defineStore('user store', {
  state: () => {
    return {
      user: {
        id: null,
        created_at: 0
      },
      token: null
    }
  },

  actions: {
    logout() {
      const store = useStore()
      localStorage.removeItem('authToken');
      localStorage.removeItem('nickname');
      this.user = {
        id: null,
        created_at: 0
      }

      store.deleteCookie('authToken')
    },

    joined(created_at) {
      if (!this.user.id) {
        return ""
      }

      if (!created_at) {
        created_at = this.user.created_at
      }

      const date = new Date(this.user.created_at)
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

      const month = months[date.getMonth()]
      const day = date.getDate()
      const year = date.getFullYear()



      return month + ' ' + day + ', ' + year;
    }
  }
})

