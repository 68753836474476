import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia } from "pinia";
import { createHead } from "@vueuse/head";
import VueGtag from "vue-gtag-next";

import Ant from "ant-design-vue";
import "ant-design-vue/dist/reset.css";

import "@/assets/style/main.scss";
import MySpoiler from "@/components/ui/spoiler.vue";

const pinia = createPinia();
const app = createApp(App);
const head = createHead();

app.component("MySpoiler", MySpoiler);

app.use(Ant);
app.use(head);
app.use(router);
app.use(pinia);

app.use(VueGtag,
  {
    property: [{ id: "G-XHFYQN7V2X" }, { id: "GTM-T2ZWL7MG" }],
    pageTrackerExcludedRoutes: [
      'make',
      'post create',
      'post edit',
      'privacy policy',
      'terms of service',
      'error'
    ],
  },);



app.mount("#app");
