import { defineStore } from 'pinia'
import { useGtag } from "vue-gtag-next";


export const useStore = defineStore('store', {
  state: () => {
    return {
      scrollBody: null,
      scrollY: 0,
      scrollLoad: false,
      scrollTop: () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      },
      registerPosts: new Set(),

    }
  },

  actions: {
    convertDateToText(dateString) {
      const date = new Date(dateString);
      const now = new Date();
      const diffMilliseconds = now - date;
      const diffSeconds = Math.floor(diffMilliseconds / 1000);
      const diffMinutes = Math.floor(diffSeconds / 60);
      const diffHours = Math.floor(diffMinutes / 60);
      const diffDays = Math.floor(diffHours / 24);
      const diffWeeks = Math.floor(diffDays / 7);
      const diffMonths = Math.floor(diffDays / 30);
      const diffYears = Math.floor(diffDays / 365);

      if (diffSeconds < 60) {
        return 'Uploaded ' + diffSeconds + ' seconds ago';
      } else if (diffMinutes < 60) {
        return 'Uploaded ' + diffMinutes + ' minutes ago';
      } else if (diffHours < 24) {
        return 'Uploaded ' + diffHours + ' hours ago';
      } else if (diffDays < 7) {
        return 'Uploaded ' + diffDays + ' days ago';
      } else if (diffWeeks < 4) {
        return 'Uploaded ' + diffWeeks + ' weeks ago';
      } else if (diffMonths < 12) {
        return 'Uploaded ' + diffMonths + ' months ago';
      } else {
        return 'Uploaded ' + diffYears + ' years ago';
      }
    },

    offScroll() {
      document.body.classList.add('_lock-scroll')
    },
    onScroll() {
      document.body.classList.remove('_lock-scroll')
    },
    async convertAVIFtoJPG(url) {
      try {
        // Fetch the AVIF image data
        const response = await fetch(url);
        const arrayBuffer = await response.arrayBuffer();

        // Create a new Blob from the AVIF data
        const avifBlob = new Blob([arrayBuffer], { type: 'image/avif' });

        // Create a new FileReader to read the AVIF data
        const fileReader = new FileReader();
        fileReader.readAsDataURL(avifBlob);

        // Once the FileReader has loaded the AVIF data
        fileReader.onload = async () => {
          // Create a new Image object from the AVIF data
          const avifImage = new Image();
          avifImage.src = fileReader.result;

          // Wait for the image to load
          await new Promise((resolve) => {
            avifImage.onload = resolve;
          });

          // Create a new canvas to draw the AVIF image
          const canvas = document.createElement('canvas');
          canvas.width = avifImage.width;
          canvas.height = avifImage.height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(avifImage, 0, 0);

          // Convert the canvas to a JPG Blob
          canvas.toBlob(
            (jpgBlob) => {
              // Create a download link for the JPG Blob
              const jpgUrl = URL.createObjectURL(jpgBlob);
              const downloadLink = document.createElement('a');
              downloadLink.href = jpgUrl;
              downloadLink.download = 'art.jpg';
              downloadLink.textContent = 'Download JPG';
              downloadLink.click()
              // document.body.appendChild(downloadLink);
            },
            'image/jpeg',
            0.8 // Quality level (0.8 = 80%)
          );
        };
      } catch (error) {
        console.error('Error converting AVIF to JPG:', error);
      }
    },

    getCookie(cookieName) {
      // Разделяем куки по точкам с запятой
      var cookies = document.cookie.split(';');

      // Проходимся по каждой куки
      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        // Удаляем пробелы с начала и конца строки
        cookie = cookie.trim();
        // Проверяем, начинается ли текущая куки с переданного имени
        if (cookie.indexOf(cookieName + '=') === 0) {
          // Если да, возвращаем значение куки (убираем имя и знак равенства)
          return cookie.substring(cookieName.length + 1);
        }
      }
      // Если куки с заданным именем не найдено, возвращаем null
      return null;
    },

    deleteCookie(name) {
      document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    },

    addPostRegister(id) {
      this.registerPosts.add(id)
    },

    clearRegisterPosts() {
      const { event } = useGtag()

      if (this.registerPosts.size >= 3) {
        event('post_viewer', {
          category: 'Engagement',
          action: 'Click',
          label: 'view >= 3 posts',
        });
      }
      this.registerPosts.clear()

      setTimeout(() => {
        this.clearRegisterPosts()
      }, 60000);
    }

  },
})

