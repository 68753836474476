<template>
  <button @click="googleAuth" class="google-button">
    <img src="@/assets/img/header/google-logo.svg" alt="" />
    <span>{{ text }}</span>
  </button>
</template>

<script setup>
import { defineProps } from "vue";

defineProps({
  text: {
    type: String,
    default: "Continue with Google account"
  }
})

function googleAuth() {
  window.location = "https://api.runliq.com/auth/redirect"
}
</script>

<style lang='scss' scoped>
.google-button {

  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
  background: var(--background);
  font-size: 12px;
  box-shadow: 0 1px 3px 1px rgba(99, 99, 99, 0.4);
  @include adaptiv-padding(5, 2.5, 40, 20, 1);
  border-radius: 5px;
  transition: all 0.3s ease 0s;

  img {
    width: 100%;
    height: 100%;
    max-width: 20px;
    max-height: 20px;
  }

  @media (any-hover: hover) {
    cursor: pointer;
  }

  &:active {
    transform: translateY(3px);
  }
}
</style>