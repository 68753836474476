import axios from "@/axios/base"

export default {
  get: async (path, config = {}) => {
    return await axios.get(process.env.VUE_APP_BACKEND_URL + `${path}`, config)
  },
  post: async (path, data, config) => {
    return await axios.post(process.env.VUE_APP_BACKEND_URL + `${path}`, data, config)
  },

  put: async (path, data) => {
    return await axios.put(`${path}`, data)
  },

  delete: async (path, data) => {
    return await axios.delete(`${path}`, data)
  },

  getUserImages: async (offset = 0) => {
    return await axios.get(process.env.VUE_APP_BACKEND_URL + `user/images?limit=12&offset=${offset}`, {
      headers: {
        Authorization: "Bearer " + localStorage.authToken
      }
    })
  },
  getPosts: async (offset = 0, limit = 12, sort = '', ...tags) => {
    return await axios.get(process.env.VUE_APP_BACKEND_URL + `posts?limit=${limit}&offset=${offset}&sort=${sort}&tags=${tags.join(',')}`, {
      headers: {
        Authorization: "Bearer " + localStorage.authToken
      }
    })
  },
  getRandomPosts: async (limit = 3) => {
    const randomOffset = (Math.random() * 100).toFixed()

    return await axios.get(process.env.VUE_APP_BACKEND_URL + `posts?limit=${limit}&offset=${randomOffset}`, {
      headers: {
        Authorization: "Bearer " + localStorage.authToken
      }
    })
  },
  getUserPosts: async (offset = 0, nickname, limit = 12) => {
    return await axios.get(process.env.VUE_APP_BACKEND_URL + `posts?limit=${limit}&offset=${offset}&by_user=${nickname}`, {
      headers: {
        Authorization: "Bearer " + localStorage.authToken
      }
    })
  },
  getPost: async (slug) => {
    return await axios.get(process.env.VUE_APP_BACKEND_URL + `posts/${slug}`, {
      headers: {
        Authorization: "Bearer " + localStorage.authToken
      }
    })
  },
  createPost: async (data) => {
    return await axios.post(process.env.VUE_APP_BACKEND_URL + `posts`,
      data,
      {
        headers: {
          Authorization: "Bearer " + localStorage.authToken
        }
      })
  },
  updatePost: async (id, data) => {
    return await axios.put(process.env.VUE_APP_BACKEND_URL + `posts/${id}`,
      data,
      {
        headers: {
          Authorization: "Bearer " + localStorage.authToken
        }
      })
  },
  getLikePosts: async (offset = 0, nickname, limit = 12) => {
    return await axios.get(process.env.VUE_APP_BACKEND_URL + `posts?limit=${limit}&offset=${offset}&liked_by_user=${nickname}`, {
      headers: {
        Authorization: "Bearer " + localStorage.authToken
      }
    })
  },
  getLikeImages: async (offset = 0, limit = 12) => {
    return await axios.get(process.env.VUE_APP_BACKEND_URL + `user/imagesLiked?limit=${limit}&offset=${offset}`, {
      headers: {
        Authorization: "Bearer " + localStorage.authToken
      }
    })
  },
  likePost: async (id) => {
    return await axios.post(process.env.VUE_APP_BACKEND_URL + `like/post/${id}`,
      {},
      {
        headers: {
          Authorization: "Bearer " + localStorage.authToken
        }
      })
  },
  unlikePost: async (id) => {
    return await axios.delete(process.env.VUE_APP_BACKEND_URL + `like/post/${id}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.authToken
        }
      })
  },
  archivePost: async (id) => {
    return await axios.delete(process.env.VUE_APP_BACKEND_URL + `posts/${id}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.authToken
        }
      })
  },
  getCommentsPost: async (id) => {
    return await axios.get(process.env.VUE_APP_BACKEND_URL + `posts/${id}/comments`, {
      headers: {
        Authorization: "Bearer " + localStorage.authToken
      }
    })
  },
  createComment: async (id, data) => {
    return await axios.post(process.env.VUE_APP_BACKEND_URL + `posts/${id}/comments`, data,
      {
        headers: {
          Authorization: "Bearer " + localStorage.authToken
        }
      })
  },
  deleteComment: async (idPost, idComment) => {
    return await axios.delete(process.env.VUE_APP_BACKEND_URL + `posts/${idPost}/comments/${idComment}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.authToken
        }
      })
  },
  updateComment: async (idPost, idComment, data) => {
    return await axios.put(process.env.VUE_APP_BACKEND_URL + `posts/${idPost}/comments/${idComment}`, data,
      {
        headers: {
          Authorization: "Bearer " + localStorage.authToken
        }
      })
  },
  likeComment: async (idComment) => {
    return await axios.post(process.env.VUE_APP_BACKEND_URL + `like/comment/${idComment}`,
      {},
      {
        headers: {
          Authorization: "Bearer " + localStorage.authToken
        }
      })
  },
  unlikeComment: async (idComment) => {
    return await axios.delete(process.env.VUE_APP_BACKEND_URL + `like/comment/${idComment}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.authToken
        }
      })
  },
  likeArt: async (id) => {
    return await axios.post(process.env.VUE_APP_BACKEND_URL + `like/image/${id}`,
      {},
      {
        headers: {
          Authorization: "Bearer " + localStorage.authToken
        }
      })
  },
  unlikeArt: async (id) => {
    return await axios.delete(process.env.VUE_APP_BACKEND_URL + `like/image/${id}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.authToken
        }
      })
  },
  archiveArt: async (id) => {
    return await axios.delete(process.env.VUE_APP_BACKEND_URL + `images/${id}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.authToken
        }
      })
  },
  updateUser: async (id, data) => {
    return await axios.put(process.env.VUE_APP_BACKEND_URL + `users/${id}`,
      data,
      {
        headers: {
          Authorization: "Bearer " + localStorage.authToken
        }
      })
  },
  updateNSFW: async (data) => {
    return await axios.put(process.env.VUE_APP_BACKEND_URL + `user/nsfw`,
      data,
      {
        headers: {
          Authorization: "Bearer " + localStorage.authToken
        }
      })
  },
  getUser: async (nickname) => {
    return await axios.get(process.env.VUE_APP_BACKEND_URL + `user/${nickname}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.authToken
        }
      })
  },
  getLoras: async (offset = 0, limit = 14) => {

    try {
      return await axios.get(process.env.VUE_APP_BACKEND_URL + `loras?limit=${limit}&offset=${offset}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.authToken
          }
        })
    } catch (error) {
      console.log(error)
    }
  },
  getModels: async (offset = 0, limit = 14) => {
    return await axios.get(process.env.VUE_APP_BACKEND_URL + `models?limit=${limit}&offset=${offset}`, {
      headers: {
        Authorization: "Bearer " + localStorage.authToken
      }
    })
  },
  getTags: async (offset = 0, limit = 30) => {
    return await axios.get(process.env.VUE_APP_BACKEND_URL + `tags?limit=${limit}&offset=${offset}`, {
      headers: {
        Authorization: "Bearer " + localStorage.authToken
      }
    })
  },
  startGen: async (data) => {
    return await axios.post(process.env.VUE_APP_BACKEND_URL + `gen/start`,
      data,
      {
        headers: {
          Authorization: "Bearer " + localStorage.authToken
        }
      })
  },
  statusGen: async (id) => {
    return await axios.get(process.env.VUE_APP_BACKEND_URL + `gen/status/${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.authToken
      }
    })
  },

}