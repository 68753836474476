<template>
  <Progress :percent="defaultPercent" :showInfo="false" :duration="5000" strokeColor="#1890ff" />
</template>
 
<script setup>
import { ref } from 'vue'
import { Progress } from 'ant-design-vue'

const defaultPercent = ref(100);
setInterval(() => {
  const percent = defaultPercent.value - 2;
  defaultPercent.value = percent < 0 ? 0 : percent;
}, 90);

</script>