import { createRouter, createWebHistory } from 'vue-router'
import { useStore } from "@/stores/store"
import { useUser } from "@/stores/user";

function trackRouteChange(route) {
  const requiredRouteNames = ['home', 'gallery', 'profile', 'profile feeds', 'profile edit', 'post']; // Array of route names to exclude

  if (requiredRouteNames.includes(route.name)) {
    // Trigger virtual pageview for non-excluded routes

    // Assuming you have GTM dataLayer installed
    const dataLayer = window.dataLayer || [];

    // Create virtual pageview data object
    const pageviewData = {
      event: 'pageview',
      page_title: route.meta.title || route.name, // Use meta title or route name
      page_path: route.fullPath, // Full URL path of the route
    };

    // Push virtual pageview data to GTM dataLayer
    dataLayer.push(pageviewData);
    // Send pageview data to Google Tag Manager
    if (window.dataLayer && window.google_tag_manager) {
      window.dataLayer.push({
        'event': 'dataLayer.push',
        'dataLayer': pageviewData
      });
    }

    // console.log(`Tracking route change: ${route.name}`); // Optional logging
  }
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/TheHome.vue')
  },
  {
    path: '/make',
    name: 'make',
    component: () => import('@/views/TheRun.vue'),

  },
  {
    path: '/gallery',
    name: 'gallery',
    component: () => import('@/views/TheGallery.vue'),
  },
  {
    path: '/post-create',
    name: 'post create',
    component: () => import('@/views/TheCreatePost.vue'),
    beforeEnter: (to, from, next) => {
      if (!localStorage.authToken) next({ name: 'home' })
      else next()
    }

  },
  {
    path: '/post-edit/:slug',
    name: 'post edit',
    component: () => import('@/views/TheEditPost.vue'),
    beforeEnter: (to, from, next) => {
      if (!localStorage.authToken) next({ name: 'home' })
      else next()
    }
  },
  {
    path: '/profile/:nickname/',
    name: 'profile',
    component: () => import('@/views/TheProfile.vue'),
    redirect: "/profile/:nickname/",
    children: [
      {
        name: 'profile feeds',
        path: '',
        component: () => import('@/views/profile/TheFeeds.vue'),
      },
      {
        name: 'profile edit',
        path: 'edit',
        component: () => import('@/views/profile/TheEdit.vue'),
        beforeEnter: (to, from, next) => {
          const store = useStore()
          const userStore = useUser()

          if (userStore.user.nickname == to.params.nickname || (store.getCookie('authToken') && to.query.q == 'login')) {
            next()
          } else {
            next('/profile/' + to.params.nickname)
          }
        }
      }
    ],
  },
  {
    path: '/p/:slug',
    name: 'post',
    component: () => import('@/views/ThePost.vue'),
  },
  {
    path: '/privacy-policy',
    name: 'privacy policy',
    component: () => import('@/views/ThePrivacyPolicy.vue'),
  },
  {
    path: '/terms-of-service',
    name: 'terms of service',
    component: () => import('@/views/TheTermsOfService.vue'),
  },
  {
    path: "/error",
    component: () => import('@/views/TheNotFound.vue')
  },
  {
    path: "/:pathMatch(.*)",
    component: () => import('@/views/TheNotFound.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeResolve((to, from, next) => {
  trackRouteChange(to); // Call trackRouteChange before resolving route
  next(); // Allow route transition to proceed
})

export default router
