<template>
  <div class="wrapper" ref="scrollBody">
    <div class="content">
      <the-header></the-header>
      <router-view class="page" />
      <the-footer></the-footer>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue"
import TheHeader from "./components/TheHeader.vue";
import TheFooter from "./components/TheFooter.vue";

import { useDark, useInfiniteScroll, useScroll } from "@vueuse/core";
import { useStore } from "@/stores/store";
import { useUser } from "@/stores/user";
import axios from "@/plugins/axios"


const store = useStore();
const userStore = useUser()

useDark();

const scrollBody = ref()
const { y } = useScroll(scrollBody);

store.scrollTop = () => {
  y.value = 0
}

useInfiniteScroll(
  scrollBody,
  () => {
    store.scrollLoad = !store.scrollLoad
  },
  { distance: 100 }
)

onMounted(async () => {
  if (localStorage.authToken) {
    try {
      const { data: user } = await axios.get("user/info", {
        headers: {
          Authorization: "Bearer " + localStorage.authToken
        }
      })

      userStore.token = localStorage.authToken
      userStore.user = user
      userStore.user.is_adult = !!userStore.user.is_adult
      localStorage.setItem('nickname', userStore.user.nickname)

      // code for save scroll position
      // store.scrollBody = scrollBody.value
    } catch (error) {
      console.error(error);
    }
  }



  if (!localStorage.loras) {
    try {
      const loras = (await axios.getLoras()).data.data
      localStorage.setItem('loras', JSON.stringify(loras))
    } catch (error) {
      console.error(error)
    }
  } else {
    try {
      const loras = (await axios.getLoras()).data.data
      try {
        if (!Array.isArray(JSON.parse(localStorage.loras)) || (JSON.parse(localStorage.loras).length < loras.length)) {
          localStorage.setItem('loras', JSON.stringify(loras))
        }
      } catch (e) {
        if (e.name == 'SyntaxError') {
          localStorage.setItem('loras', JSON.stringify(loras))
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  if (!localStorage.models) {
    try {
      const models = (await axios.getModels()).data.data
      localStorage.setItem('models', JSON.stringify(models))
    } catch (error) {
      console.error(error)
    }
  } else {
    try {
      const models = (await axios.getModels()).data.data
      try {
        if (!Array.isArray(JSON.parse(localStorage.models)) || (JSON.parse(localStorage.models).length < models.length)) {
          localStorage.setItem('models', JSON.stringify(models))
        }
      } catch (e) {
        if (e.name == 'SyntaxError') {
          localStorage.setItem('models', JSON.stringify(models))
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  store.clearRegisterPosts()
})
</script>