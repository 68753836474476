<template>
  <a-dropdown class="header__dropdown" v-model:open="userMenu">
    <a class="header__user ant-dropdown-link" @click.prevent>
      <UserOutlined />
      <span class="header__username">{{ storeUser.user.nickname }}</span>
    </a>
    <template #overlay>
      <a-menu class="header__dropdown-menu">
        <router-link class="header__menu-link" :to="'/profile/' + storeUser.user.nickname" @click="userMenu = false">
          <a-menu-item key="profile">
            <span>Your Profile</span>
          </a-menu-item>
        </router-link>
        <a-menu-item key="adult">
          <span>Adult (18+)</span>
          <a-switch class="header__menu-user-switch" @change="changeNSFW" v-model:checked="storeUser.user.is_adult"
            size="small" />
        </a-menu-item>
        <!-- <a-menu-item key="credits" @click="router.push('/liq-credits'); userMenu = false">
          <span class="red">Liq Credits</span>
        </a-menu-item> -->
        <!-- <router-link class="header__menu-link" :to="'/profile/' + storeUser.user.nickname + '/likes'"
          @click="userMenu = false">
          <a-menu-item key="likes">
            <span>Your Likes</span>
          </a-menu-item>
        </router-link> -->

        <router-link class="header__menu-link" :to="'/post-create'" @click="userMenu = false">
          <a-menu-item key="make">
            <span>Create Post</span>
          </a-menu-item>
        </router-link>

        <a-menu-item key="theme">
          <span>Darkmode</span>
          <a-switch class="header__menu-user-switch" @change="userMenu = false" v-model:checked="isDark" size="small" />
        </a-menu-item>
        <a-menu-item key="logout" @click="logOut(); userMenu = false">
          <span>Log Out</span>
        </a-menu-item>
      </a-menu>
    </template>
  </a-dropdown>
</template>

<script setup>
import { ref, defineEmits } from "vue";
import { UserOutlined } from "@ant-design/icons-vue";
import { useDark } from "@vueuse/core";
import { useUser } from "@/stores/user";
import axios from "@/plugins/axios"
import { useAlert } from "@/stores/alert";

const storeUser = useUser();
const storeAlert = useAlert();

const isDark = useDark();

defineEmits(["logOut"]);
const userMenu = ref(false);


function logOut() {
  storeUser.logout()
}

async function changeNSFW() {
  try {

    await axios.updateNSFW({
      is_adult: storeUser.user.is_adult
    })

    userMenu.value = false
  }
  catch {
    storeAlert.setAlert('error', 'Something went wrong.')
  }
}
</script>

<style lang='scss' scoped>
.header {
  &__user {
    display: flex;
    align-items: center;
    @include adaptiv-value(column-gap, 10, 5, 1);
    background: transparent;
    color: var(--black);
    @include adaptiv-value(padding, 16, 8, 1);
    position: relative;

    &::after {
      content: "";
      width: 100%;
      height: 2px;
      position: absolute;
      bottom: 0;
      left: 0;
    }

    @media (any-hover: hover) {
      cursor: pointer;
      transition: all 0.3s ease 0s;

      &:hover {
        color: #1890ff;

        &::after {
          background-color: #1890ff;
        }
      }
    }

    &.ant-dropdown-open {
      color: #1890ff;
      border-color: #1890ff;
    }
  }

  &__menu-user-switch {
    @include adaptiv-value(margin-left, 15, 10, 1);
  }

  &__menu-link {
    display: block;
    border-radius: 5px;

    &.router-link-exact-active {

      background: var(--bg-select-drop-down);

      span {
        color: var(--color-select-drop-down);
      }
    }
  }

  @media (max-width: 400px) {
    &__username {
      display: none;
    }

    &__user {
      font-size: 20px;
    }
  }
}
</style>