import { defineStore } from 'pinia'
import { notification, message } from 'ant-design-vue';
import { h } from 'vue';
import { ExclamationCircleOutlined, InfoCircleOutlined, CheckCircleOutlined } from '@ant-design/icons-vue';
import Progress from "@/components/ui/progressNotf.vue"
import GoogleButton from "@/components/ui/GoogleButton.vue"


export const useAlert = defineStore('alert', {
  state: () => {
    return {
      types: {
        error: { icon: ExclamationCircleOutlined, params: { style: 'color: #FA541C' } },
        warning: { icon: ExclamationCircleOutlined, params: { style: 'color: #FAAD14' } },
        success: { icon: CheckCircleOutlined, params: { style: 'color: #52C41A' } },
        info: { icon: InfoCircleOutlined, params: { style: 'color: #1890FF' } },
      }
    }
  },
  actions: {
    setAlert(type, text = '', message = 'Something wrong', html = []) {

      notification.open({
        message: message,
        duration: 5,
        description: () =>
          h('div', [
            h('p', { style: 'white-space: pre-line' },
              text),
            ...html.map((el) => h(el.type, el.props, el.child)),

            h(Progress, { class: 'notification-progress' }),

          ]),
        icon: () => h(this.types[type].icon, this.types[type].params),
      });

    },

    alertLogin(text = '', message = 'Please login.') {
      notification.open({
        message: message,
        duration: 5,
        description: () =>
          h('div', [
            h('p', { style: 'white-space: pre-line' },
              text),

            h(GoogleButton, { text: 'Login by Google' }),
            h(Progress, { class: 'notification-progress' }),

          ]),
        icon: () => h(this.types.info.icon, this.types.info.params),
      });
    },

    setMessage(type, text = 'message') {
      const messages = {
        success: message.success,
        warning: message.warning,
        error: message.error,
        info: message.info,
      }

      messages[type](text)

    }
  }
})

