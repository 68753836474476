<template>
  <header class="header">
    <div class="header__wrapper">
      <div class="header__container _container">
        <div class="header__body">
          <div class="header__left">
            <div class="header__burger" @click="burgerOpen">
              <span></span><span></span><span></span>
            </div>
            <router-link :to="'/'" class="header__logo">
              <span class="header__logo-icon">
                <HeartOutlined />
              </span>
              <span class="header__logo-text">RunLiq.com</span>
            </router-link>
          </div>
          <div class="header__right">
            <nav class="header__nav">
              <a-menu v-model:selectedKeys="current" mode="horizontal" class="header__nav-menu">
                <a-menu-item v-for="item in menu" :key="item.key" @click="item.to">
                  <template #icon>
                    <component :is="item.icon" :style="{ color: item.iconColor }"></component>
                  </template>
                  {{ item.title }}
                </a-menu-item>
              </a-menu>

              <!-- <span class="header__nav-token header__nav-item">
                <span>LC: 235</span>
              </span> -->

              <div class="header__login-menu" v-if="!userStore.user.id">
                <a-button type="dashed" class="header__login" @click="loginMenu = !loginMenu">
                  <template #icon>
                    <UserOutlined />
                  </template>
                  Login
                </a-button>

                <Transition name="fade">
                  <ul class="header__login-menu-list" v-if="loginMenu">
                    <GoogleButton class="header__google-auth" v-if="!userStore.user.id" />

                  </ul>
                </Transition>
              </div>

              <user-menu v-else />
            </nav>


          </div>
        </div>

        <BurgerMenu :menu="menu" v-model="burger" :isAuth="userStore.user" @close="burgerOpen" />
      </div>
    </div>
  </header>
</template>

<script setup>
import { useRouter, useRoute } from "vue-router";
import { ref, watch } from "vue";
import {
  RocketOutlined,
  // HomeOutlined,
  FolderOpenOutlined,
  UserOutlined,
  HeartOutlined
} from "@ant-design/icons-vue";

import GoogleButton from "./ui/GoogleButton.vue";
import UserMenu from "@/components/header/TheUserMenu.vue";
import BurgerMenu from "@/components/header/TheBurgerMenu.vue";

import { useStore } from "@/stores/store";
import { useUser } from "@/stores/user";

const store = useStore();
const userStore = useUser()
const route = useRoute();
const router = useRouter();

const burger = ref(false);

const loginMenu = ref(false)

const menu = ref([
  {
    title: "New Run",
    key: "make",
    icon: RocketOutlined,
    to: () => router.push("/make"),
    iconColor: "#1890FF",
  },
  // {
  //   title: "Home",
  //   key: "home",
  //   icon: HomeOutlined,
  //   to: () => router.push("/"),
  // },
  {
    title: "Gallery",
    key: "gallery",
    icon: FolderOpenOutlined,
    to: () => router.push("/gallery?sort=last"),
  },
]);

const current = ref([route.name ? route.name : ""]);

watch(
  () => route.name,
  () => {
    current.value = [
      route.matched.length > 1
        ? route.matched[route.matched.length - 2].name
        : route.name,
    ];
  }
);

function burgerOpen() {
  burger.value = !burger.value;

  if (burger.value) {
    store.offScroll();
  } else {
    store.onScroll();
  }
}

</script>

<style lang="scss" scoped>
.header {
  &__body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include adaptiv-padding(14, 7, 0, 0, 1);
  }

  &__left {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }

  &__burger {
    display: none;
    flex-direction: column;
    width: 25px;
    height: 25px;
    row-gap: 6px;
    justify-content: center;

    span {
      width: 100%;
      height: 2.5px;
      border-radius: 1px;
      background-color: var(--black);
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    @include adaptiv-value(column-gap, 8, 4, 1);
    color: var(--darkBlue);
    font-family: $fontGeometria;
    @include adaptiv-font(22, 16);
    padding: 3px 0;

    &-icon {
      color: $colorVolcano;
      @include adaptiv-font(40, 30);
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__nav {
    display: flex;
    align-items: center;

    &-link {
      display: flex;
      align-items: center;
      @include adaptiv-value(column-gap, 10, 5, 1);
      color: var(--black);
      font-size: 14px;
      position: relative;
      line-height: 22px;
      @include adaptiv-padding(12, 6, 0, 0, 1);

      &::after {
        content: "";
        position: absolute;
        width: 0;
        height: 2px;
        background: #1890ff;
        left: 0;
        bottom: 0;
        transition: all 0.3s ease 0s;
      }

      @media (any-hover: hover) {
        cursor: pointer;
        transition: all 0.3s ease 0s;

        &:hover {
          color: #1890ff;

          &:after {
            width: 100%;
          }
        }
      }

      &.router-link-active {
        color: #1890ff;

        &:after {
          width: 100%;
        }
      }
    }

    &-item {
      @include adaptiv-margin(0, 0, 15, 7.5, 1);
    }

    &-menu {
      border: 0;
      background: var(--background);
      color: var(--black);
    }
  }

  &__run {
    & .header__icon {
      color: #1890ff;
    }
  }

  &__login-menu {
    display: flex;
    flex-direction: column;
    position: relative;

    &-list {
      position: absolute;
      width: 270px;
      right: 0;
      display: flex;
      flex-direction: column;
      z-index: 40;
      top: calc(100% + 15px);
    }
  }

  &__login {
    padding: 4px 15px;
    @include adaptiv-font(14, 11);
    @include adaptiv-line-height(22, 18);
    color: $colorBlue;
    border-color: $colorBlue;
    background: transparent;
    border-radius: 12px;

    @media (any-hover: hover) {
      cursor: pointer;
      transition: all 0.3s ease 0s;

      &:hover {
        background: transparent;
      }
    }
  }

  @media screen and (max-width: 770px) {
    &__body-mob {
      display: flex;
    }

    &__burger {
      display: flex;
    }

    &__nav-menu {
      display: none;
    }

    &__backward {
      display: block;
    }
  }

  @media (max-width: 430px) {
    &__body {
      .header__nav-token {
        display: none;
      }
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
}
</style>